/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline"

export default function Lightbox({
  open,
  setOpen,
  currentItem,
  setCurrentItem,
}) {

  useEffect(() => {
    if (currentItem && currentItem.photo && currentItem.photo.edition_options) {
      const myEditOption = currentItem.photo.edition_options;
      if (myEditOption.crop_options) {
        currentItem.photo.thumbnailUrlComponent = `${currentItem.photo.url.trim()}?rect=${Math.floor(myEditOption.crop_options.x)},${Math.floor(myEditOption.crop_options.y)},${Math.floor(myEditOption.crop_options.width)},${Math.floor(myEditOption.crop_options.height)}&fm=jpg&fit=crop&h=500&w=600`;
      } else {
        currentItem.photo.thumbnailUrlComponent = `${currentItem.photo.url.trim()}?fm=jpg&fit=crop&h=500&w=600`
      }
    }

  })

  const cancelButtonRef = useRef(null);

  return (
    currentItem && (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-90 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">

            <button
                className="fixed top-0 right-0 text-white rounded-md"
                onClick={() => setOpen(false)}
                style={{
                  top: 10,
                  right: 10,
                  zIndex: 1
                }}
              >
                <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="25.81"
                  viewBox="0 0 40 26"
                  style={{
                    position: "relative",
                    left: "0px",
                    top: "-10px",
                  }}
                >
                  <path
                    d="M40,.14c-1.42,9.76-9.82,17.26-19.98,17.26H0"
                    style={{
                      fill: "none",
                      stroke: "#fff",
                      strokeMiterlimit: 10,
                      strokeWidth: 1,
                    }}
                  />
                </svg>
              </button>

            <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-right shadow-xl transition-all max-w-5xl w-full mx-auto">
                  <div className="grid grid-cols-6">
                    <div className="md:col-span-4 sm:col-span-6 col-span-6 place-items-center w-full team-image-lightbox team-images-lb">
                      <img src={`${currentItem.photo.url.trim()}?rect=${Math.floor(currentItem.photo.edition_options.crop_options.x)},${Math.floor(currentItem.photo.edition_options.crop_options.y)},${Math.floor(currentItem.photo.edition_options.crop_options.width)},${Math.floor(currentItem.photo.edition_options.crop_options.height)}&fm=jpg&fit=crop&h=500&w=600`} alt="Workflow" />
                    </div>
                    <div className="team-lightbox-1 md:col-span-2 sm:col-span-6 col-span-6 flex flex-col justify-center text-start ml-6 mt-6">
                      <p className="tracking-widest text-3xl text-white">
                        {currentItem.name}
                      </p>
                      <p className="team-lightbox-2 tracking-widest text-md text-white">
                        {currentItem.role}
                      </p>

                      <p className="team-lightbox-3 tracking-widest text-md text-white mt-8">
                        {currentItem.description}
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  )
}
