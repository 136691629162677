import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"

import TeamLightbox from "../components/TeamLightbox"
import TeamSlider from "../components/TeamSlider"

import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"

import logo_main from "../images/logo-main.png"
import logo_main_fade from "../images/logo-main-fade.png"
import servicesImg1 from "../images/services_1.png"
import servicesImg2 from "../images/services_2.png"
import servicesImg3 from "../images/services_3.png"

import teamImg from "../images/roup.jpg"

const AboutUs = () => {
  const url = typeof window !== "undefined" ? window.location.pathname : ""

  const [open, setOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [height, setHeight] = useState(100)
  const [crew, setCrew] = useState([])
  const [simpleCrew, setsimpleCrew] = useState([])

  useEffect(() => {
    fetch("https://api.whitebrim.co/api/model/6351989605e277b6eaf1a05b/team")
      .then(response => response.json())
      .then(data => {
        data.results.sort((a, b) => a.ordering.global - b.ordering.global)
        data.results.forEach(el => {
          if (el.photo && el.photo.edition_options) {
            const myEditOption = el.photo.edition_options
            if (myEditOption.crop_options) {
              el.thumbnailUrlComponent = `${el.photo.url.trim()}?rect=${Math.floor(
                myEditOption.crop_options.x
              )},${Math.floor(myEditOption.crop_options.y)},${Math.floor(
                myEditOption.crop_options.width
              )},${Math.floor(
                myEditOption.crop_options.height
              )}&fm=jpg&fit=crop&h=500&w=600`
            } else {
              el.thumbnailUrlComponent = `${el.photo.url.trim()}?fm=jpg&fit=crop&h=500&w=600`
            }
          }
        })
        setCrew([...data.results]);
        setsimpleCrew(data.results);
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [])

  const boundBox = useRef(null)

  useEffect(() => {
    setHeight(boundBox.current.clientHeight)
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search)
      const teamId = params.get("team")

      if (teamId) {
        const foundTeam = crew.find(team => team._id === teamId)
        setCurrentItem(foundTeam)
        setOpen(true)
      }
    }
  }, [])

  return (
    <Layout>
      <Navbar title="About Us" />

      <div
        className="hidden lg:grid absolute bg-green green-cut"
        style={{ height }}
      ></div>
      <div ref={boundBox}>
        <section
          className="w-screen bg-white py-18 px-6"
          style={{ overflow: "hidden" }}
        >
          <div className="container mx-auto">
            <div className="mx-auto max-w-2xl pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-6 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24 items-center">
              <div
                className="about-img col-span-3 md:block hidden"
                style={{ maxWidth: "120%" }}
                src={teamImg}
                alt="Workflow"
              />
              <div className="col-span-3 mb-10 text-aboutus-1 pt-20">
                <img
                  className="md:hidden mx-auto pb-10"
                  style={{
                    width: "150%",
                    margin: "auto",
                    position: "absolute",
                    top: "16%",
                    left: "0%",
                  }}
                  src={logo_main_fade}
                  alt="Workflow"
                />
                <p className="lg:max-w-3xl leading-normal tracking-widest text-black text-center pt-4 px-4 text-fade">
                  Founded in 1992, AG Films is an independent, film production
                  company connecting iconic brands and stories with audiences
                  worldwide.
                  <br />
                  <br />
                  Based in Lisbon, Portugal, AG Films encompasses the
                  development, production, and distribution of cross-platform
                  content to deliver a full range of film, TV and advertising
                  services.
                </p>
              </div>
              <div
                className="md:hidden about-img"
                style={{ maxWidth: "150%", left: "-18%" }}
                src={teamImg}
                alt="Workflow"
              />
            </div>
          </div>
        </section>

        <section className="w-screen overflow-x-hidden bg-white">
          <div className="container mx-auto">
            <div className="mx-auto max-w-3xl pt-11 pb-16 lg:grid lg:max-w-7xl lg:grid-cols-8 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:pt-16 ">
              <div className="col-span-5">
                <p className="text-aboutus-3 font-bold leading-normal tracking-widest md:max-w-xl md:text-start max-w-sm text-center mx-auto ">
                  WE LOVE PORTUGAL
                </p>
                <p className="text-aboutus-2 leading-normal tracking-widest text-black md:text-start text-center py-4 px-4">
                  Portugal’s diversity offers a broad range of shooting
                  locations for any film project requirement. With an
                  architecture ranging from ancient to modern, Portugal provides
                  a great production stand-in for nearly any country requirement
                </p>
                <p className="text-aboutus-2 leading-normal tracking-widest text-black md:text-start  text-center py-4 px-4">
                  Portugal’s stunning biodiversity offers lush green forests,
                  rugged coastlines, mountains, deserts and sandy beaches - all
                  just for a short drive from Lisbon. Portugal really has it
                  all. For “all season” shoots, we offer full “Snow FX” services
                  to meet your most challenging production needs.
                </p>
                <p className="text-aboutus-2 leading-normal tracking-widest text-black md:text-start  text-center px-4">
                  In addition to this, Portugal offers an attractive cash rebate
                  program of up to 30% for international productions. Our
                  experienced team of administrators can submit and ensure your
                  proposal is dealt with in a swift and simple manner to ensure
                  the maximum production value for your project
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section
        className="w-screen overflow-hidden bg-white ag-team"
        id="ag-team"
      >
        <div className="container ml-auto team-container">
          <div className="hidden lg:grid max-w-8xl pb-24 grid grid-cols-3 grid-rows-3">
            {crew?.slice(0, 3).map((item, i) => (
              <div
                className="img-unit relative cursor-pointer team-images"
                onClick={() => {
                  window.history.pushState({}, "", `${url}?team=${item._id}`)
                  setOpen(true)
                  setCurrentItem(item)
                }}
              >
                <img
                  className=""
                  src={`${item.thumbnailUrlComponent}`}
                  alt="Workflow"
                />
                <div className="team-anime flex items-center">
                  <p className="aboutus-team-animate absolute tracking-widest text-black text-center mx-8">
                    {item.name}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-7 h-7 absolute right-40 mx-auto border-r-2 border-b-2 rounded-none border-gray-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            ))}
            <div className="my-auto ml-20">
              <h2 className="text-aboutus-5 text-start tracking-widest py-4">
                MEET OUR TEAM
              </h2>
              <p className="text-aboutus-6 text-3xl max-w-xs font-light leading-normal tracking-widest text-black text-start">
                GET TO KNOW THE FRIENDLY NAMES AND FACES BEHIND THE CAMERA.
              </p>
            </div>
            {crew?.slice(3, 5).map((item, i) => (
              <div
                className="img-unit relative cursor-pointer team-images"
                // style={
                //   i === 0 ? {marginLeft: 'calc(20% + 20px)'} : i === 1 ? {} : {}
                // }
                onClick={() => {
                  window.history.pushState({}, "", `${url}?team=${item.id}`)
                  setOpen(true)
                  setCurrentItem(item)
                }}
              >
                <img
                  className=""
                  src={`${item.thumbnailUrlComponent}`}
                  alt="Workflow"
                />
                <div className="team-anime flex items-center">
                  <p className="aboutus-team-animate absolute tracking-widest text-black text-center mx-8">
                    {item.name}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-7 h-7 absolute right-40 mx-auto border-r-2 border-b-2 rounded-none border-gray-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            ))}

            {crew?.slice(5, 8).map((item, i) => (
              <div
                className="img-unit relative cursor-pointer team-images"
                onClick={() => {
                  window.history.pushState({}, "", `${url}?team=${item.id}`)
                  setOpen(true)
                  setCurrentItem(item)
                }}
              >
                <img
                  className=""
                  src={`${item.thumbnailUrlComponent}`}
                  alt="Workflow"
                />
                <div className="team-anime flex items-center">
                  <p className="aboutus-team-animate absolute tracking-widest text-black text-center mx-8">
                    {item.name}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-7 h-7 absolute right-40 mx-auto border-r-2 border-b-2 rounded-none border-gray-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            ))}
            <TeamLightbox
              open={open}
              setOpen={setOpen}
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
            />
          </div>
        </div>
      </section>

      <TeamSlider team={simpleCrew} />

      <section className="w-screen overflow-x-hidden bg-white">
        <div className="container mx-auto pt-8">
          <p className="text-aboutus-7 my-8 text-5xl 5xl:text-5xl font-bold leading-normal text-center">
            OUR SERVICES
          </p>
          <div className="mx-auto max-w-2xl pt-10 pb-16 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:pt-16 lg:pb-24">
            <div>
              <img
                className="h-30 w-30 mx-auto p-10"
                style={{ height: 200 }}
                src={servicesImg1}
                alt="Workflow"
              />

              <h2 className="text-aboutus-5 text-center py-10 uppercase xs:px-2">
                Production Services
              </h2>
              <p className="text-aboutus-4 text-md font-light leading-normal tracking-widest text-black text-center xs:px-2">
                From Pre-Production to Delivery. We offer production services
                for Advertising, TV and Film.
                <br />
                AG also has 100% success claiming ICA rebates for Movies and TV
                Series filmed in Portugal.
              </p>
            </div>
            <div className="xs:px-2">
              <img
                className="h-30 w-30 mx-auto p-10"
                style={{ height: 200 }}
                src={servicesImg2}
                alt="Workflow"
              />
              <h2 className="text-aboutus-5 text-center py-10 uppercase xs:px-2">
                Location Database
              </h2>
              <p className="text-aboutus-4 text-md font-light leading-normal tracking-widest text-black text-center">
                With 30 years of experience producing Commercials, Movies, TV
                Series and photo campaigns, we have built up one of Portugal's
                largest location databases.
                <br />
                We pride ourselves on finding the hidden gems this wonderful
                country has to offer. worldwide.
              </p>
            </div>
            <div>
              <img
                className="h-30 w-30 mx-auto p-10"
                style={{ height: 200 }}
                src={servicesImg3}
                alt="Workflow"
              />

              <h2 className="text-aboutus-5 text-center py-10 uppercase px-2">
                Full service for Movies and TV
              </h2>
              <p className="text-aboutus-4 text-md font-light leading-normal tracking-widest text-black text-center xs:px-2">
                In 2022 AG Films added Film and TV development to the range of
                services we provide.
                <br />
                Have a look at the projects we have in different phases of
                production and development.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <Footer />
    </Layout>
  )
}

export default AboutUs
