import React, { useState } from "react"
import Slider from "react-slick"

import TeamLightbox from "../components/TeamLightbox"

import "slick-carousel/slick/slick-theme.css"

import "../assets/css/slick.css"

export default function TeamCarousel({ team }) {
  const url = typeof window !== "undefined" ? window.location.pathname : ""

  const [open, setOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const sliderSettings = {
    speed: 0,
    autoplay: false,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    centerMode: false,
    focusOnSelect: true,
    adaptiveHeight: true,
    dots: true,
    mobileFirst: true,
  }

  return (
    team && (
      <div className="w-screen overflow-x-hidden">
        <div className="grid grid-cols-1 lg:hidden">
          <div className="mx-auto">
            <h2 className="text-center tracking-widest py-4">MEET OUR TEAM</h2>
            <div className="md:hidden absolute right-0 w-full h-3/6 green-cut-sm bg-green"></div>
            <h1 className="text-aboutus-6 max-w-md font-light leading-normal tracking-widest text-black text-center py-4">
              GET TO KNOW THE <br />
              FRIENDLY NAMES
              <br /> AND FACES BEHIND
              <br />
              THE CAMERA.
            </h1>
          </div>

          <div className="team-image-slider">
            <Slider {...sliderSettings}>
              {team.map(teamEl => (
                <img
                  key={teamEl._id}
                  className="slider"
                  alt=""
                  src={`${teamEl.photo.url.trim()}?rect=${Math.floor(teamEl.photo.edition_options.crop_options.x)},${Math.floor(teamEl.photo.edition_options.crop_options.y)},${Math.floor(teamEl.photo.edition_options.crop_options.width)},${Math.floor(teamEl.photo.edition_options.crop_options.height)}&fm=jpg&fit=crop&h=500&w=600`}
                  onClick={() => {
                    window.history.pushState({}, "", `${url}?teamEl=${teamEl._id}`)
                    setOpen(true)
                    setCurrentItem(teamEl)
                  }}
                />
              ))}
            </Slider>
          </div>
          <TeamLightbox
            open={open}
            setOpen={setOpen}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
          />
        </div>
      </div>
    )
  )
}
